import React, { useState, useLayoutEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import SEO from 'components/SEO/SEO';
import { black, white } from 'shared/colors';
import { Font, Text } from 'components/Text/Text';
import { BREAKPOINTS } from 'shared/constants';
import GoogleMapReact from 'google-map-react';
import { convertToVW } from 'shared/utils';
const bghc = require('images/herov2.jpg');
const bg = require('images/where-to-buy-bg.jpg');

interface Props {
  location: {
    pathname: string;
  };
}

let dispenaries = [
  {
    title: 'Calma',
    address1: '1155 N La Brea Ave',
    address2: 'West Hollywood, CA 90038',
    phone: ' 323-498-0035',
    website: 'https://calmawesthollywood.com',
    lat: 34.092380,
    lng: -118.344120,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Calivia - Hanford',
    address1: '104 N Douty St,',
    address2: 'Hanford, CA 93230',
    phone: '(888) 688-0303',
    website: 'https://www.caliva.com/dispensary/hanford',
    lat: 36.3267285,
    lng: -119.6458199,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Caliva - San Jose',
    address1: '1695 S 7th St,',
    address2: 'San Jose, CA 95112',
    phone: '(623) 877-3934',
    website: 'https://caliva.com/dispensary/san-jose',
    lat: 37.313622,
    lng: -121.866676,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Coastal - Santa Barbara',
    address1: '1019 Chapala St,',
    address2: 'Santa Barbara, CA 93101',
    phone: '(805) 380-7730',
    website: 'https://coastalcalifornia.com/location/coastal-santa-barbara',
    lat: 34.420712,
    lng: -119.703987,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Coastal - Stockton',
    address1: '7840 West Ln Unit F,',
    address2: 'Stockton, CA 95210',
    phone: '209-762-3909',
    website: 'http://coastalstockton.com',
    lat: 38.019880,
    lng: -121.293400,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Coastal - West LA',
    address1: '2435 Military Ave,',
    address2: 'Los Angeles, CA 90064',
    phone: '(424) 293-2479',
    website: 'https://coastalcalifornia.com/location/coastal-west-la',
    lat: 34.037300,
    lng: -118.432678,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Ponderosa Dispensary',
    address1: '9240 W Northern Ave Ste 103B,',
    address2: 'Glendale, AZ 85305',
    phone: '623-877-3934',
    website: 'https://www.pondyaz.com/',
    lat: 33.5508821,
    lng: -112.3123201,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Varda',
    address1: '3341 E Colorado Blvd',
    address2: 'Pasadena, CA  91107',
    phone: '626-469-8500',
    website: 'http://vardadispensary.com',
    lat: 34.146600,
    lng: -118.082250,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
  {
    title: 'Zen Garden',
    address1: '7632 Pacific Ave,',
    address2: 'Stockton, California',
    phone: '209-435-3900',
    website: 'https://dutchie.com/dispensary/zen-garden-wellness',
    lat: 38.0187676,
    lng: -121.3234883,
    distance: 0,
    delivery: false,
    deliverytext: '',
  },
];

const WhereToBuy = ({ location: { pathname } }: Props) => {
  const [jsonArray, setJsonArray] = useState(dispenaries);
  const [active, setActive] = useState(0);

  const [name, setName] = useState(jsonArray[0].title);
  const [address1, setAddress1] = useState(jsonArray[0].address1);
  const [address2, setAddress2] = useState(jsonArray[0].address2);
  const [phone, setPhone] = useState(jsonArray[0].phone);
  const [site, setSite] = useState(jsonArray[0].website);
  const [map, setMap] = useState(
    'http://maps.google.com/?&daddr=' +
      (jsonArray[0].address1 + '+' + jsonArray[0].address2)
        .replace(/^[, ]+|[, ]+$|[, ]+/g, '+')
        .trim()
  );
  const [lat, setLat] = useState(jsonArray[0].lat);
  const [lng, setLng] = useState(jsonArray[0].lng);
  const [delivery, setDelivery] = useState(jsonArray[0].delivery);
  const [deliverytext, setDeliverytext] = useState(jsonArray[0].deliverytext);
  const [call, setCall] = useState('tel:' + jsonArray[0].phone);

  const [loading, setLoading] = useState(true);

  const [toggleLightbox, setToggleLightbox] = useState(false);
  const closeLightbox = () => setToggleLightbox(false);
  const openLightbox = () => setToggleLightbox(true);

  const mapOptions = {
    styles: [
      {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
          { saturation: 36 },
          { color: '#000000' },
          { lightness: 40 },
          { visibility: 'off' },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          { visibility: 'off' },
          { color: '#000000' },
          { lightness: 16 },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }, { lightness: 20 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#000000' }, { lightness: 17 }, { weight: 1.2 }],
      },
      {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }, { visibility: 'simplified' }],
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry.fill',
        stylers: [{ visibility: 'simplified' }],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'geometry.fill',
        stylers: [{ visibility: 'simplified' }],
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'geometry.fill',
        stylers: [{ visibility: 'simplified' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.fill',
        stylers: [{ visibility: 'simplified' }],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }, { lightness: 20 }],
      },
      {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }, { lightness: 21 }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ lightness: '-78' }, { color: '#000000' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }, { lightness: 18 }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }, { lightness: 16 }],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }, { lightness: 19 }],
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#000000' }, { lightness: 17 }],
      },
    ],
    zoomControl: false,
    fullscreenControl: false,
  };

  const Marker = (props: any) => {
    return (
      <FontAwesomeIcon
        icon={faMapMarkerAlt}
        size="3x"
        className={css(styles.marker)}
      />
    );
  };

  const isClient = typeof window !== 'undefined';

  function distance(lat1, lng1, lat2, lng2, miles) {
    // miles optional
    if (typeof miles === 'undefined') {
      miles = false;
    }
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
    function square(x) {
      return Math.pow(x, 2);
    }
    var r = 6371; // radius of the earth in km
    lat1 = deg2rad(lat1);
    lat2 = deg2rad(lat2);
    var lat_dif = lat2 - lat1;
    var lng_dif = deg2rad(lng2 - lng1);
    var a =
      square(Math.sin(lat_dif / 2)) +
      Math.cos(lat1) * Math.cos(lat2) * square(Math.sin(lng_dif / 2));
    var d = 2 * r * Math.asin(Math.sqrt(a));
    if (miles) {
      return d * 0.621371;
    } //return miles
    else {
      return d;
    } //return km
  }

  function sortJSON(arr, key) {
    return arr.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  useLayoutEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;

        dispenaries.map((item, i) => {
          var howFar = distance(userLat, userLng, item.lat, item.lng, true);
          if (item.delivery == false) {
            item.distance = howFar;
          } else if (item.delivery == true) {
            item.distance = 0;
          }
        });

        const sortedDispenaries = sortJSON(dispenaries, 'distance');
        setJsonArray(sortedDispenaries);
        setName(jsonArray[0].title);
        setAddress1(jsonArray[0].address1);
        setAddress2(jsonArray[0].address2);
        setPhone(jsonArray[0].phone);
        setSite(jsonArray[0].website);
        setMap(
          'http://maps.google.com/?&daddr=' +
            (jsonArray[0].address1 + '+' + jsonArray[0].address2)
              .replace(/^[, ]+|[, ]+$|[, ]+/g, '+')
              .trim()
        );
        setLat(jsonArray[0].lat);
        setLng(jsonArray[0].lng);
        setCall('tel:' + jsonArray[0].phone);
      });

      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [jsonArray]);

  if (loading) {
    return (
      <div className={css(styles.loadingContainer)}>
        <h3 className={css(styles.loadingText)}>LOADING</h3>
      </div>
    );
  }

  return (
    <>
      <SEO title="Where to Buy | MONOGRAM" pathname={pathname} />
      <main className={css(styles.container)}>
        <div className={css(styles.mapWrapper)}>
          <img
            id="hero-bg"
            className={css(styles.map)}
            src={bg}
            alt="Reviewed by Accessible 360"
          />

          <div className="mapGoogle" style={{ height: '100%', width: '100%' }}>
            {isClient && !delivery && (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyAPPrfOivuzoOrM0-QoHz18ihV0aFVDueI',
                }}
                center={[lat, lng]}
                zoom={14}
                options={mapOptions}
                backgroundColor={'none'}
              >
                <Marker lat={lat} lng={lng} text="My Marker"></Marker>
              </GoogleMapReact>
            )}
          </div>
          <div className={css(styles.mapText)}>
            {name && <p className={css(styles.mapTextTitle)}>{name}</p>}
            {deliverytext && (
              <p className={css(styles.mapTextAd)}>{deliverytext}</p>
            )}
            {address1 && (
              <p className={css(styles.mapTextAd)}>
                {address1} {address2}
              </p>
            )}
            {phone && (
              <a href={call} className={css(styles.telLink)}>
                <p className={css(styles.mapTextPhone)}>{phone}</p>
              </a>
            )}
          </div>
          <div className={css(styles.mapCta)}>
            {site && (
              <a href={site} target="_blank">
                <button className={css(styles.button)}>
                  <span className={css(styles.buttonText)}>WEBSITE</span>
                </button>
              </a>
            )}
            {address1 && (
              <a href={map} target="_blank">
                <button className={css(styles.button)}>
                  <span className={css(styles.buttonText)}>Open In Maps</span>
                </button>
              </a>
            )}
          </div>
        </div>

        {toggleLightbox && (
          <div className={css(styles.mapWrapperMobile)}>
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              className={css(styles.closeLightboxBtn)}
              onClick={() => {
                closeLightbox();
              }}
            />
            <img
              id="hero-bg"
              className={css(styles.map)}
              src={bg}
              alt="Reviewed by Accessible 360"
            />

            <div
              className="mapGoogle"
              style={{ height: '100%', width: '100%' }}
            >
              {isClient && !delivery && (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyAPPrfOivuzoOrM0-QoHz18ihV0aFVDueI',
                  }}
                  center={[lat, lng]}
                  zoom={14}
                  options={mapOptions}
                  backgroundColor={'none'}
                >
                  <Marker lat={lat} lng={lng} text="My Marker" />
                </GoogleMapReact>
              )}
            </div>
            <div className={css(styles.mapText)}>
              {name && <p className={css(styles.mapTextTitle)}>{name}</p>}
              {deliverytext && (
                <p className={css(styles.mapTextAd)}>{deliverytext}</p>
              )}
              {address1 && (
                <p className={css(styles.mapTextAd)}>
                  {address1} {address2}
                </p>
              )}
              {phone && (
                <a href={call} className={css(styles.telLink)}>
                  <p className={css(styles.mapTextPhone)}>{phone}</p>
                </a>
              )}
            </div>
            <div className={css(styles.mapCta)}>
              {site && (
                <a href={site} target="_blank">
                  <button className={css(styles.button)}>
                    <span className={css(styles.buttonText)}>WEBSITE</span>
                  </button>
                </a>
              )}
              {address1 && (
                <a href={map} target="_blank">
                  <button className={css(styles.button)}>
                    <span className={css(styles.buttonText)}>Open In Maps</span>
                  </button>
                </a>
              )}
              {phone && (
                <a href={call}>
                  <button className={css(styles.button)}>
                    <span className={css(styles.buttonText)}>Call</span>
                  </button>
                </a>
              )}
            </div>
          </div>
        )}

        <div className={css(styles.shopWrapper)}>
          {jsonArray.map((item, i) => {
            if (active == i) {
              var roundedDistance = Math.round(item.distance);
              var distanceText = `${roundedDistance} mile${
                roundedDistance === 1 ? '' : 's'
              }`;

              return (
                <div
                  key={i}
                  className={css(
                    styles.dispensaryContainer,
                    styles.activeDispensary
                  )}
                  onClick={() => {
                    setName(item.title);
                    setAddress1(item.address1);
                    setAddress2(item.address2);
                    setPhone(item.phone);
                    setSite(item.website);
                    setMap(
                      'http://maps.google.com/?&daddr=' +
                        (item.address1 + '+' + item.address2)
                          .replace(/^[, ]+|[, ]+$|[, ]+/g, '+')
                          .trim()
                    );
                    setLat(item.lat);
                    setLng(item.lng);
                    setCall('tel:' + item.phone);
                    setDelivery(item.delivery);
                    setDeliverytext(item.deliverytext);
                    setActive(i);
                    openLightbox();
                  }}
                >
                  <h3 className={css(styles.h3Active)}>{item.title}</h3>
                  {item.distance > 0 && (
                    <p className={css(styles.distanceAwayActive)}>
                      {distanceText}
                    </p>
                  )}
                  {item.delivery && (
                    <p className={css(styles.distanceAwayActive)}>0 MILES</p>
                  )}
                  {item.delivery && (
                    <p className={css(styles.pActive)}>
                      DELIVERED TO YOUR DOOR
                      <br />
                      <br />
                      <br />
                    </p>
                  )}
                  <p className={css(styles.pActive)}>
                    {item.address1}
                    <br />
                    {item.address2}
                    <br />
                    <br />
                    <a href={call} className={css(styles.telLinkActive)}>
                      {item.phone}
                    </a>
                  </p>
                </div>
              );
            } else {
              var roundedDistance = Math.round(item.distance);
              var distanceText = `${roundedDistance} mile${
                roundedDistance === 1 ? '' : 's'
              }`;

              return (
                <div
                  key={i}
                  className={css(styles.dispensaryContainer)}
                  onClick={() => {
                    setName(item.title);
                    setAddress1(item.address1);
                    setAddress2(item.address2);
                    setPhone(item.phone);
                    setSite(item.website);
                    setMap(
                      'http://maps.google.com/?&daddr=' +
                        (item.address1 + '+' + item.address2)
                          .replace(/^[, ]+|[, ]+$|[, ]+/g, '+')
                          .trim()
                    );
                    setLat(item.lat);
                    setLng(item.lng);
                    setCall('tel:' + item.phone);
                    setDelivery(item.delivery);
                    setDeliverytext(item.deliverytext);
                    setActive(i);
                    openLightbox();
                  }}
                >
                  <h3 className={css(styles.h3)}>{item.title}</h3>
                  {item.distance > 0 && (
                    <p className={css(styles.distanceAway)}>{distanceText}</p>
                  )}
                  {item.delivery && (
                    <p className={css(styles.distanceAway)}>0 MILES</p>
                  )}
                  {item.delivery && (
                    <p className={css(styles.p)}>
                      DELIVERED TO YOUR DOOR
                      <br />
                      <br />
                      <br />
                    </p>
                  )}
                  <p className={css(styles.p)}>
                    {item.address1}
                    <br />
                    {item.address2}
                    <br />
                    <br />
                    <a href={call} className={css(styles.telLink)}>
                      {item.phone}
                    </a>
                  </p>
                </div>
              );
            }
          })}
        </div>
        <div className={css(styles.productCtaContainer)}>
          <img
            id="herov2"
            className={css(styles.productCta)}
            src={bghc}
            alt="Reviewed by Accessible 360"
          />
          <div className={css(styles.textWrapper)}>
            <p className={css(styles.subheader)}>HYPE WILLIAMS X SLIM AARONS</p>
            <h1 className={css(styles.title)}>THE GOOD LIFE, REDEFINED</h1>
            <div className={css(styles.buttonWrapperHardcode)}>
              <a href="/editorial/thegoodliferedefined">
                <button className={css(styles.buttonHardcode)}>
                  <p className={css(styles.buttonTextHardcode)}>
                    view behind the scenes
                  </p>
                </button>
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WhereToBuy;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    paddingTop: '120px',
    left: '0',
    fontFamily: 'Titling Gothic FB Normal Regular',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: black,
    color: white,
    width: '100vw',
    height: 'auto',

    [BREAKPOINTS.TABLET]: {
      paddingTop: '90px',
      height: 'auto',
      paddingBottom: '0',
    },
  },
  mapWrapper: {
    position: 'absolute',
    width: '50vw',
    height: 'calc(100vh - 120px)',
    left: 0,
    borderRight: '1px solid white',

    [BREAKPOINTS.TABLET]: {
      position: 'fixed',
      display: 'none',
      top: '90px',
      width: '100vw',
      height: 'calc(100vh - 90px)',
      borderRight: '0',
      borderBottom: '1px solid white',
      zIndex: 60,
      backgroundColor: black,
    },
  },
  mapWrapperMobile: {
    display: 'none',
    position: 'absolute',
    width: '50vw',
    height: 'calc(100vh - 120px)',
    left: 0,
    borderRight: '1px solid white',

    [BREAKPOINTS.TABLET]: {
      display: 'block',
      position: 'fixed',
      top: '90px',
      width: '100vw',
      height: '150vh',
      marginTop: '-50vh',
      borderRight: '0',
      borderBottom: '1px solid white',
      zIndex: 60,
      backgroundColor: black,
    },
  },
  map: {
    display: 'none',
    position: 'absolute',
    width: '50vw',
    height: 'calc(100vh - 120px)',
    objectFit: 'cover',
    filter: 'blur(2px)',

    [BREAKPOINTS.TABLET]: {
      backgroundColor: black,
      opacity: 0,
      width: '100vw',
      height: 'calc(100vh - 90px)',
    },
  },
  mapGoogle: {
    position: 'absolute',

    [BREAKPOINTS.TABLET]: {},
  },
  marker: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '40px',
    height: '40px',
    transform: 'translate(-50%, -50%)',
  },
  mapText: {
    position: 'absolute',
    top: 'calc(50% - 60px)',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -100%)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      top: 'calc(50% - 60px)',
      transform: 'translate(-50%, -100%)',
    },
  },
  mapTextTitle: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    fontWeight: 'bold',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '10px',
  },
  mapTextAd: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Normal Regular',
    color: white,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    padding: '0 20px 10px 20px',
    width: '100%',
  },
  mapTextPhone: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Normal Regular',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  mapCta: {
    position: 'absolute',
    top: 'calc(50% + 40px)',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, 0%)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      top: 'calc(50% + 40px)',
      transform: 'translateX(-50%)',
    },
  },
  button: {
    position: 'relative',
    cursor: 'pointer',
    textTransform: 'uppercase',
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    backgroundColor: black,
    color: white,
    margin: '0 auto',
    padding: '20px',
    marginTop: '20px',
    border: `1px solid ${white}`,
    ':focus': {
      border: `4px solid ${white}`,
    },
    ':hover': {
      backgroundColor: white,
      border: `1px solid ${white}`,
      transition: '0.3s ease-in-out',
    },
    ':hover > span': {
      color: black,
      transition: 'color 0.3s ease-in-out',
    },

    [BREAKPOINTS.TABLET]: {
      width: '100%',
      maxWidth: 'calc(100vw - 40px)',
      margin: '20px 20px 0 20px',
    },
  },
  buttonText: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Wide',
  },
  shopWrapper: {
    position: 'relative',
    width: '50vw',
    height: 'calc(100vh - 120px)',
    left: '50vw',
    margin: '0',
    overflowY: 'scroll',
    boxSizing: 'border-box',

    [BREAKPOINTS.TABLET]: {
      left: 0,
      top: '0',
      width: '100vw',
      height: '100%',
    },
  },
  dispensaryContainer: {
    position: 'relative',
    padding: '20px',
    height: '120px',
    borderBottom: '1px solid white',
    cursor: 'pointer',
    backgroundColor: black,

    ':hover': {
      backgroundColor: '#222',
      transition: 'background-color 0.3s ease-in-out',

      [BREAKPOINTS.TABLET]: {
        transition: 'none',
        backgroundColor: black,
      },
    },

    ':active': {
      backgroundColor: black,
    },
  },
  h3: {
    fontSize: '10px',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Wide',
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    color: white,
    lineHeight: 1.2,

    [BREAKPOINTS.TABLET]: {
      fontSize: '10px',
    },
  },
  distanceAway: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '10px',
    fontFamily: 'Titling Gothic FB Normal Regular',
    color: white,
    textTransform: 'uppercase',
    textAlign: 'right',
    lineHeight: 1.2,
    opacity: 0.3,

    [BREAKPOINTS.TABLET]: {
      fontSize: '10px',
    },
  },
  p: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    fontSize: '10px',
    fontFamily: 'Titling Gothic FB Normal Regular',
    color: white,
    textTransform: 'uppercase',
    textAlign: 'right',
    lineHeight: 1.2,

    [BREAKPOINTS.TABLET]: {
      fontSize: '10px',
    },
  },
  a: {
    fontSize: 14,
    color: white,
  },
  activeDispensary: {
    backgroundColor: '#fff',

    [BREAKPOINTS.TABLET]: {
      backgroundColor: '#000',
    },

    ':hover': {
      backgroundColor: '#fff',

      [BREAKPOINTS.TABLET]: {
        backgroundColor: '#000',
      },
    },
  },
  h3Active: {
    fontSize: '10px',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Wide',
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    lineHeight: 1.2,
    color: black,
    transition: 'color 0.3s ease-in-out',

    [BREAKPOINTS.TABLET]: {
      fontSize: '10px',
      color: white,
    },
  },
  distanceAwayActive: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '10px',
    fontFamily: 'Titling Gothic FB Normal Regular',
    textTransform: 'uppercase',
    textAlign: 'right',
    lineHeight: 1.2,
    color: black,
    transition: 'color 0.3s ease-in-out',
    opacity: 0.3,

    [BREAKPOINTS.TABLET]: {
      fontSize: '10px',
      color: white,
    },
  },
  pActive: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    fontSize: '10px',
    fontFamily: 'Titling Gothic FB Normal Regular',
    textTransform: 'uppercase',
    textAlign: 'right',
    lineHeight: 1.2,
    color: black,
    transition: 'color 0.3s ease-in-out',

    [BREAKPOINTS.TABLET]: {
      fontSize: '10px',
      color: white,
    },
  },
  closeLightboxBtn: {
    display: 'none',
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: 'calc(50vh + 20px)',
    right: '20px',
    color: white,

    [BREAKPOINTS.TABLET]: {
      display: 'block',
      zIndex: 100,
    },
  },
  telLink: {
    textDecoration: 'none',
    color: white,
  },
  telLinkActive: {
    textDecoration: 'none',
    color: black,

    [BREAKPOINTS.TABLET]: {
      color: white,
    },
  },
  productCta: {
    display: 'block',
    filter: 'brightness(0.5)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  productCtaContainer: {
    position: 'relative',
    backgroundColor: black,
    borderTop: '1px solid white',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vw * 9 / 16)',

    [BREAKPOINTS.TABLET]: {
      top: 0,
      height: '100vw',
      borderTop: 'none',
    },
  },
  textWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      width: 'calc(100vw - 80px)',
    },
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  title: {
    color: white,
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    paddingBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  buttonWrapperHardcode: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  buttonHardcode: {
    color: black,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
  },
  buttonTextHardcode: {
    fontSize: 12,
    color: black,
    fontFamily: 'Titling Gothic FB Wide',
    [BREAKPOINTS.TABLET]: {},
  },
  loadingContainer: {
    position: 'relative',
    paddingTop: '120px',
    left: '0',
    backgroundColor: black,
    color: white,
    width: '100vw',
    height: '100vh',

    [BREAKPOINTS.TABLET]: {
      paddingTop: '90px',
    },
  },
  loadingText: {
    position: 'absolute',
    top: 'calc(50% + 60px)',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontFamily: 'Titling Gothic FB Wide',
    fontWeight: 500,
    fontSize: 14,

    [BREAKPOINTS.TABLET]: {
      top: 'calc(50% + 45px)',
    },
  },
});
